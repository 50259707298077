<template>
    <div class="cart">
        <h3>Authorized Agency</h3>
        <h6>授权机构</h6>
        <p>重庆安标检测研究院有限公司（国家安全生产重庆矿用设备检测检验中心）</p>
        <p>国家煤矿防尘通风安全产品质量检验检测中心</p>
        <p>国家矿山安全计量站</p>
        <p>煤炭工业重庆安全仪器检验站</p>
        <p>煤炭工业重庆电气防爆检验站</p>
        <p>国家烟尘粉尘测量仪型式评价实验室（重庆煤矿）</p>
        <p>国家易燃易爆气体检测（报警）仪型式评价实验室（重庆煤矿）</p>
    </div>
</template>
<script>
export default {
    name: 'authorized'
}
</script>
<style scoped>
h6{
    margin-bottom: 18px;
}
p {
    padding-left: 16px;
    line-height: 40px;
    height: 40px;
    background: #F7F7F7;
    border-radius: 8px;
    font-size: 16px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #333;
    margin-bottom: 8px;
}
</style>